import type { GroupLanding } from "~/types/GroupLanding";

export function createGroupLandingLink({
  groupLandingList,
  branchId,
  lang,
}: {
  groupLandingList: GroupLanding[];
  branchId: string | number | null;
  lang: string;
}) {
  if (!Array.isArray(groupLandingList)) {
    return false;
  }
  if (!branchId) {
    return false;
  }
  const checkIsBelong = groupLandingList.filter((group) => {
    if (group.attributes) {
      if (group.attributes.branchId) {
        return group.attributes.branchId === branchId;
      }
    }
    return false;
  });
  if (checkIsBelong && checkIsBelong.length) {
    return `/${lang}/${checkIsBelong[0].attributes.slug}`;
  }
  return false;
}
