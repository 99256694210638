import { uniqBy } from "lodash-es";
import { toSlug } from "./string";
import type { GroupLanding } from "~/types/GroupLanding";
import type { FeaturedRestaurant } from "~/types/Restaurant";
import { createGroupLandingLink } from "~/services/group_landing/groupLandingLink";
import { getFinalLang } from "~/services/common/language";

export function linkToRestaurantPage(slug: string) {
  if (!slug) {
    return "";
  }
  return `/restaurants/${slug}`;
}

export function linkSearhByCuisine(cuisineId: string | number) {
  return `/restaurants/search?cuisines[]=${cuisineId}`;
}

export function linkSearchByTags(tagId: string | number) {
  const usedLang = getFinalLang();
  return `/${usedLang}/restaurants/search?hashtags=${tagId}`;
}

export function createLoopId({
  name,
  id,
}: {
  name: string;
  id: number | string | null;
}) {
  return `${toSlug(name)}-${id}`;
}
export const generateBranchLink = function generateBranchLink({
  branchId,
  slug,
}: {
  branchId: string;
  slug: string;
}) {
  if (branchId) {
    return `/restaurants/search?branch_id=${branchId}`;
  } else if (slug) {
    return `/restaurants/${slug}`;
  }
};
export function belongToGroupLanding({
  groupLandingList,
  branchId,
}: {
  groupLandingList: GroupLanding[];
  branchId: string | number | null;
}) {
  if (!Array.isArray(groupLandingList)) {
    return false;
  }
  if (!branchId) {
    return false;
  }
  const checkIsBelong = groupLandingList.filter((group) => {
    if (group.attributes) {
      if (group.attributes.branchId) {
        return group.attributes.branchId === +branchId;
      }
    }
    return false;
  });
  if (checkIsBelong && checkIsBelong.length) {
    return `/${checkIsBelong[0].attributes.slug}`;
  }
  return false;
}

export function generateRestaurantLink({
  type,
  slug,
  groupLandingList,
  branchId,
  domain,
  lang,
}: {
  type: string;
  slug: string;
  groupLandingList: GroupLanding[];
  branchId: string | number | null;
  lang: string;
  domain?: string;
}) {
  const determineLink = () => {
    if (type === "restaurants") {
      return `/restaurants/${slug}`;
    } else if (type === "featured_restaurants") {
      const groupLandingLink = createGroupLandingLink({
        groupLandingList,
        branchId,
        lang,
      });
      if (groupLandingLink) {
        return groupLandingLink;
      }
      if (branchId) {
        return `/restaurants/search?branch_id=${branchId}`;
      }
      if (slug) {
        return `/restaurants/${slug}`;
      }
      return "";
    }
    return "";
  };

  return domain ? `${domain}${determineLink()}` : determineLink();
}

export function getYoutubeIds(videosArray: string[]): string[] {
  if (Array.isArray(videosArray)) {
    const youtubeVideos = videosArray.filter((video) => {
      return video.includes("youtube") && video.includes("embed");
    });
    const youtubeIds = youtubeVideos.map((youtubeVid) => {
      const isContainQueryString = youtubeVid.indexOf("?");
      const removedQueryString =
        isContainQueryString !== -1
          ? youtubeVid.slice(0, isContainQueryString)
          : youtubeVid;
      const splitEmbed = removedQueryString.split("embed/");
      if (splitEmbed.length) {
        return splitEmbed[1];
      }
      return "";
    });
    return youtubeIds;
  }
  return [];
}

export function removeDuplicateByAttributes(
  data: FeaturedRestaurant[],
  param: keyof FeaturedRestaurant["attributes"]
) {
  return uniqBy(data, (item) => item.attributes[param]);
}
